<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 @isShow="isShow" ref="childTab1" />
      </el-tab-pane>
      <el-tab-pane label="审批流转" name="second" :key="'second'">
        <childTab2 :isShow="isSho" @draftState="draftState" />
      </el-tab-pane>
      <el-tab-pane label="相关文档" name="third" :key="'third'">
        <childTab3 />
      </el-tab-pane>
      <el-tab-pane label="关联信息" name="fourth" :key="'fourth'">
        <childTab4 />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import PinvEditMain from '@/views/FinanceManagement/PinvchangeManage/TabChild/PinvNewEditMain.vue';
import PinvEditAppr from '@/views/FinanceManagement/PinvchangeManage/TabChild/PinvEditAppr';
import PinvEditAbout from '@/views/FinanceManagement/PinvchangeManage/TabChild/PinvEditAbout';
import RelationList from './TabChild/RelationList.vue';
export default {
  name: 'PinvEdit',
  components: {
    childTab1: PinvEditMain,
    childTab2: PinvEditAppr,
    childTab3: PinvEditAbout,
    childTab4: RelationList
  },
  data() {
    return {
      activeName: 'first',
      isSho: true
    };
  },
  created() {},
  methods: {
    isShow(val) {
      this.isSho = val;
    },
    draftState() {
      this.$refs.childTab1.initData();
    }
  }
};
</script>

<style scoped></style>
