import { render, staticRenderFns } from "./PinvNewEditMain.vue?vue&type=template&id=248d545c&scoped=true&"
import script from "./PinvNewEditMain.vue?vue&type=script&lang=js&"
export * from "./PinvNewEditMain.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "248d545c",
  null
  
)

export default component.exports