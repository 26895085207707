var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vg_wrapper"},[_c('EditHeader',{attrs:{"isShow":_vm.isShow,"btn":_vm.btn,"special":true,"strForm":{ status: _vm.formStatus }},on:{"openEdit":_vm.openEdit,"closeEdit":_vm.closeEdit,"submit":_vm.submit,"refresh":_vm.initData}},[_c('div',{attrs:{"slot":"otherButton"},slot:"otherButton"},[_c('div',{staticClass:"flexHV vg_mr_8"},[_c('span',[_vm._v("单据号：")]),_c('el-input',{staticStyle:{"width":"160px"},attrs:{"disabled":"","size":"small","value":_vm.pinv_no}})],1)]),_c('div',{attrs:{"slot":"specialStatus"},slot:"specialStatus"},[_c('div',{staticClass:"flexHV"},[_c('span',{staticClass:"vg_tag_label"},[_vm._v("状态：")]),_c('el-tag',{attrs:{"type":_vm.helper.getStatusName(_vm.formStatus).type}},[_vm._v(_vm._s(_vm.helper.getStatusName(_vm.formStatus).name))])],1)])]),_c('DynamicForm',_vm._b({ref:"dynamicForm",staticClass:"dynamicForm",attrs:{"total-width":_vm.mainForm.totalWidth,"split":_vm.mainForm.split,"form-list":_vm.mainForm.formProperties,"is-show":_vm.isShow,"computedData":{ tran_nos: _vm.tran_nos, scon_nos: _vm.scon_nos, invoice_nos: _vm.invoice_nos, podr_nos: _vm.podr_nos, new_ship_no: _vm.new_ship_no }}},'DynamicForm',_vm.$attrs,false)),_c('div',{staticClass:"vg_mb_8",staticStyle:{"display":"flex"}},[_c('el-button',{attrs:{"type":"success","plain":"","size":"small","disabled":_vm.isShow},on:{"click":_vm.surcharge}},[_vm._v("含入附加费")])],1),_c('DynamicUTable',{ref:"dynamicUTable",attrs:{"tableData":_vm.subTableData,"columns":_vm.pinvSubTableProperties,"need-pagination":false,"is-show":_vm.isShow,"show-summary":true,"row-click":function (row, column, event) { return (_vm.currentRow = row); },"computedData":{
      pinv_part_subtotal: _vm.pinv_part_subtotal,
      pinv_actu_price: _vm.pinv_actu_price,
      pinv_part_no_subtotal: _vm.pinv_part_no_subtotal,
      tax_accrued: _vm.tax_accrued,
      tax_refund: _vm.tax_refund,
      prod_usubtotal: _vm.prod_usubtotal,
      pinv_actu_accrued: _vm.pinv_actu_accrued
    },"summariesColumns":[
      'pinv_part_num',
      'pinv_part_subtotal',
      'prod_tgweight',
      'prod_tnweight',
      'pinv_actu_num',
      'pinv_actu_subtotal',
      'pinv_part_no_subtotal',
      'pinv_actu_no_subtotal',
      'tax_accrued',
      'tax_refund',
      'prod_usubtotal',
      'tax_accrued',
      'tax_refund',
      'pinv_actu_accrued'
    ],"needSummaryValues":['pinv_part_num', 'pinv_part_subtotal', 'pinv_actu_num', 'pinv_actu_subtotal', 'pinv_actu_accrued']},on:{"selection-change":function (val) { return (_vm.subSelections = val); },"getSummary_pinv_part_num":_vm.changePinvPartNum,"getSummary_pinv_part_subtotal":_vm.changePinvPartSubtotal,"getSummary_pinv_actu_num":_vm.changePinvActuNum,"getSummary_pinv_actu_subtotal":_vm.changePinvActuSubtotal,"getSummary_pinv_actu_accrued":_vm.changeTaxAccrued},scopedSlots:_vm._u([{key:"subItem-prod_hscode",fn:function(scope){return [_c('el-link',{staticClass:"vg_cursor",attrs:{"type":"primary","disabled":_vm.isShow},on:{"click":function($event){return _vm.showTranPartDialog(scope.$index)}}},[_vm._v("选择 ")])]}}])}),_c('el-dialog',{attrs:{"title":"海关编码","width":"70%","visible":_vm.dialogTableVisible},on:{"update:visible":function($event){_vm.dialogTableVisible=$event}}},[_c('customsList',{on:{"changeCustom":_vm.changeCustom}})],1),_c('el-row',{staticClass:"vg_mt_16 vd_bortop"},[_c('el-col',[_c('inputUser',{ref:"userData",attrs:{"isCookie":false,"stffForm":_vm.stffForm}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }