<template>
  <div class="vg_wrapper">
    <EditHeader
      :isShow="isShow"
      :btn="btn"
      :special="true"
      :strForm="{ status: formStatus }"
      @openEdit="openEdit"
      @closeEdit="closeEdit"
      @submit="submit"
      @refresh="initData"
    >
      <div slot="otherButton">
        <div class="flexHV vg_mr_8">
          <span>单据号：</span>
          <el-input disabled size="small" :value="pinv_no" style="width: 160px"></el-input>
        </div>
      </div>
      <div slot="specialStatus">
        <div class="flexHV">
          <span class="vg_tag_label">状态：</span>
          <el-tag :type="helper.getStatusName(formStatus).type">{{ helper.getStatusName(formStatus).name }}</el-tag>
        </div>
      </div>
    </EditHeader>
    <DynamicForm
      v-bind="$attrs"
      :total-width="mainForm.totalWidth"
      :split="mainForm.split"
      :form-list="mainForm.formProperties"
      ref="dynamicForm"
      class="dynamicForm"
      :is-show="isShow"
      :computedData="{ tran_nos: tran_nos, scon_nos: scon_nos, invoice_nos: invoice_nos, podr_nos: podr_nos, new_ship_no: new_ship_no }"
    />
    <div class="vg_mb_8" style="display: flex">
      <el-button type="success" plain size="small" :disabled="isShow" @click="surcharge">含入附加费</el-button>
    </div>
    <DynamicUTable
      ref="dynamicUTable"
      :tableData="subTableData"
      :columns="pinvSubTableProperties"
      :need-pagination="false"
      :is-show="isShow"
      @selection-change="val => (subSelections = val)"
      :show-summary="true"
      :row-click="(row, column, event) => (currentRow = row)"
      :computedData="{
        pinv_part_subtotal: pinv_part_subtotal,
        pinv_actu_price: pinv_actu_price,
        pinv_part_no_subtotal: pinv_part_no_subtotal,
        tax_accrued: tax_accrued,
        tax_refund: tax_refund,
        prod_usubtotal: prod_usubtotal,
        pinv_actu_accrued: pinv_actu_accrued
      }"
      :summariesColumns="[
        'pinv_part_num',
        'pinv_part_subtotal',
        'prod_tgweight',
        'prod_tnweight',
        'pinv_actu_num',
        'pinv_actu_subtotal',
        'pinv_part_no_subtotal',
        'pinv_actu_no_subtotal',
        'tax_accrued',
        'tax_refund',
        'prod_usubtotal',
        'tax_accrued',
        'tax_refund',
        'pinv_actu_accrued'
      ]"
      :needSummaryValues="['pinv_part_num', 'pinv_part_subtotal', 'pinv_actu_num', 'pinv_actu_subtotal', 'pinv_actu_accrued']"
      @getSummary_pinv_part_num="changePinvPartNum"
      @getSummary_pinv_part_subtotal="changePinvPartSubtotal"
      @getSummary_pinv_actu_num="changePinvActuNum"
      @getSummary_pinv_actu_subtotal="changePinvActuSubtotal"
      @getSummary_pinv_actu_accrued="changeTaxAccrued"
    >
      <template v-slot:subItem-prod_hscode="scope">
        <el-link type="primary" class="vg_cursor" :disabled="isShow" @click="showTranPartDialog(scope.$index)">选择 </el-link>
      </template>
    </DynamicUTable>
    <el-dialog title="海关编码" width="70%" :visible.sync="dialogTableVisible">
      <customsList @changeCustom="changeCustom"></customsList>
    </el-dialog>
    <el-row class="vg_mt_16 vd_bortop">
      <el-col>
        <inputUser :isCookie="false" :stffForm="stffForm" ref="userData"></inputUser>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EditHeader from '@/views/component/editHeaderLine.vue';
import inputUser from '@/views/component/inputUser.vue';
import DynamicForm from '@/components/DynamicFormV2.vue';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import SearchTable from '@/components/table/SearchTableV2.vue';
import customsList from '@/views/FinanceManagement/PinvManage/TabChild/Componet/CustomsList.vue';
import { pinvMainFormProperties, pinvSubTableProperties } from '@/views/FinanceManagement/PinvManage/pinv';
import { pinvAPI } from '@api/modules/pinv';
import { tranAPI } from '@api/modules/tran';
import { getNoCatch, postNoCatch } from '@api/request';
import { changeStaffForm, getCptt, getDataCenterByPermId, getSupp, getUnit } from '@api/public';
import { cloneDeep } from 'lodash';
import { BigNumber } from 'bignumber.js';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import { arrayDeRepeat, getArrayIds } from '@assets/js/arrayUtils';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'pinvEditMain',
  components: {
    customsList,
    SearchTable,
    DynamicUTable,
    DynamicForm,
    EditHeader,
    inputUser
  },
  data() {
    return {
      isShow: true,
      btn: {},
      currentRow: {},
      stffForm: {},
      splitForm: {},
      mainForm: {
        split: 8,
        totalWidth: 24,
        formProperties: cloneDeep(pinvMainFormProperties)
      },
      subTableData: [],
      subSelections: [],
      pinvSubTableProperties: cloneDeep(pinvSubTableProperties),
      formStatus: 0,
      pinv_no: '',
      pinv_id: '',
      pinvchange_id: '',
      dialogTableVisible: false,
      openIndex: '',
      suppList: []
    };
  },
  computed: {
    // 子表出运发票号
    tran_nos() {
      let nos = arrayDeRepeat(getArrayIds(this.subTableData, 'tran_no').filter(x => !!x)).toString();
      this.mainForm.formProperties.find(({ prop }) => prop === 'tran_nos').model = nos;
      return nos;
    },
    // 子表外销发票号
    scon_nos() {
      let nos = arrayDeRepeat(getArrayIds(this.subTableData, 'scon_no').filter(x => !!x)).toString();
      this.mainForm.formProperties.find(({ prop }) => prop === 'scon_nos').model = nos;
      return nos;
    },
    // 子表发票号
    invoice_nos() {
      let nos = arrayDeRepeat(getArrayIds(this.subTableData, 'invoice_no').filter(x => !!x)).toString();
      this.mainForm.formProperties.find(({ prop }) => prop === 'invoice_nos').model = nos;
      return nos;
    },
    // 子表采购合同号
    podr_nos() {
      let nos = arrayDeRepeat(getArrayIds(this.subTableData, 'podr_no').filter(x => !!x)).toString();
      this.mainForm.formProperties.find(({ prop }) => prop === 'podr_nos').model = nos;
      return nos;
    },
    // 子表新出运发票号
    new_ship_no() {
      let nos = arrayDeRepeat(getArrayIds(this.subTableData, 'new_ship_no').filter(x => !!x)).toString();
      this.mainForm.formProperties.find(({ prop }) => prop === 'new_ship_no').model = nos;
      return nos;
    },
    // 收票金额 = 收票数量 * 收票单价 + 附加费
    pinv_part_subtotal() {
      if (!this.currentRow) return;
      let { pinv_part_num, pinv_part_price, surcharge } = this.currentRow;
      let temp = new BigNumber(pinv_part_num).times(pinv_part_price).plus(surcharge || 0);
      if (!temp.isFinite()) {
        this.currentRow.pinv_part_subtotal = 0;
        return '自动计算';
      }
      this.currentRow.pinv_part_subtotal = temp.toFixed(4);
      return temp.toFixed(4);
    },
    // 实际收票单价 = 实际不含税金额 / 实际收票数量
    pinv_actu_price() {
      if (!this.currentRow) return;
      let { pinv_actu_no_subtotal, pinv_actu_num } = this.currentRow;
      let temp = new BigNumber(pinv_actu_no_subtotal).div(pinv_actu_num);
      if (!temp.isFinite()) {
        this.currentRow.pinv_actu_price = 0;
        return '自动计算';
      }
      this.currentRow.pinv_actu_price = temp.toFixed(2);
      return temp.toFixed(2);
    },
    // 不含税金额 = 收票金额 - 纳税额
    pinv_part_no_subtotal() {
      if (!this.currentRow) return;
      let { pinv_part_subtotal, tax_accrued } = this.currentRow;
      // let temp = new BigNumber(pinv_part_subtotal).div(1 + tax_rate);
      let temp = new BigNumber(pinv_part_subtotal).minus(tax_accrued);
      if (!temp.isFinite()) {
        this.currentRow.pinv_part_no_subtotal = 0;
        return '自动计算';
      }
      this.currentRow.pinv_part_no_subtotal = temp.toFixed(2);
      return temp.toFixed(2);
    },
    // 纳税额 = 实际收票金额 - (实际收票金额 / ( 1 + 税率 / 100))
    tax_accrued() {
      let { pinv_actu_subtotal, tax_rate } = this.currentRow;
      // let temp = new BigNumber((pinv_actu_subtotal / (1 + tax_rate)) * tax_rate);
      let temp = new BigNumber(pinv_actu_subtotal - pinv_actu_subtotal / (1 + tax_rate / 100));
      if (!temp.isFinite()) {
        this.currentRow.tax_accrued = 0;
        return '自动计算';
      }
      this.currentRow.tax_accrued = temp.toFixed(2);
      return temp.toFixed(2);
    },
    // 退税额 = 实际收票金额 / ( 1 + 退税率 / 100) * ( 退税率 / 100)
    tax_refund() {
      let { pinv_actu_subtotal, tax_refundrate } = this.currentRow;
      // let temp = new BigNumber(pinv_actu_subtotal).times(tax_refundrate).div(100);
      let temp = new BigNumber(pinv_actu_subtotal - pinv_actu_subtotal / (1 + tax_refundrate / 100));
      if (!temp.isFinite()) {
        this.currentRow.tax_refund = 0;
        return '自动计算';
      }
      this.currentRow.tax_refund = temp.toFixed(2);
      return temp.toFixed(2);
    },
    // 报关金额 = 开票数量 * 报关单价
    prod_usubtotal() {
      let { pinv_part_num, prod_usdprice } = this.currentRow;
      let temp = new BigNumber(pinv_part_num).times(prod_usdprice);
      if (!temp.isFinite()) {
        this.currentRow.prod_usubtotal = 0;
        return '自动计算';
      }
      this.currentRow.prod_usubtotal = temp.toFixed(2);
      return temp.toFixed(2);
    },
    // 新增实际纳税额 = 实际收票金额 - 实际不含税金额
    pinv_actu_accrued() {
      let { pinv_actu_subtotal, pinv_actu_no_subtotal } = this.currentRow;
      let temp = new BigNumber(pinv_actu_subtotal).minus(pinv_actu_no_subtotal);
      if (!temp.isFinite()) {
        this.currentRow.pinv_actu_accrued = 0;
        return '自动计算';
      }
      this.currentRow.pinv_actu_accrued = temp.toFixed(2);
      return temp.toFixed(2);
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      this.getPinvData();
      await this.changeProperties();
    },
    async changeProperties() {
      this.mainForm.formProperties.find(({ prop }) => prop === 'cust_dept_id').show = false;
      this.mainForm.formProperties.find(({ prop }) => prop === 'pinv_cptt_id').options = await getCptt();
      this.suppList = await getSupp();
      let subTableSupp = this.pinvSubTableProperties.find(({ prop }) => prop === 'supp_id');
      subTableSupp.subItem.options = this.suppList;
      subTableSupp.subItem.change = (val, row) => (row.supp_abbr = this.suppList.find(({ value }) => value === val)?.label ?? '');
      //改为中文单位
      let unitArr = await getUnit('select', 'param1');
      this.pinvSubTableProperties.find(({ prop }) => prop === 'prod_unit').subItem.options = unitArr;
      this.pinvSubTableProperties.find(({ prop }) => prop === 'pinv_part_unit').subItem.options = unitArr;
      let suppAbbrColumn = this.mainForm.formProperties.find(({ prop }) => prop === 'supp_abbr');
      suppAbbrColumn.options = Array.from(this.suppList, ({ label }) => {
        return { value: label, label: label };
      });
      suppAbbrColumn.change = val => (this.supp_id = this.suppList.find(({ label }) => label === val)?.value);
      //报关中文品名 & 联动修改海关编码
      let optList = await getDataCenterByPermId({ id: 10019, type: 'no' });
      this.pinvSubTableProperties.find(({ prop }) => prop === 'prod_name').subItem.options = Array.from(optList, ({ param2 }) => {
        return { value: param2, label: param2 };
      });
      this.pinvSubTableProperties.find(({ prop }) => prop === 'prod_name').subItem.change = (val, row, options) => {
        let find = optList.find(item => item.param2 === val);
        this.$set(row, 'prod_hscode', find.param1);
      };
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message.info('已撤销编辑');
        })
        .catch(() => {});
    },
    getPinvData() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      getNoCatch(pinvAPI.getPinvByIdChange, { pinvchange_id: props.form_id }).then(({ data }) => {
        this.btn = data.btn;
        this.pinv_no = data.form.pinv_no;
        this.supp_id = data.form.supp_id;
        this.pinvchange_id = data.form.pinvchange_id;
        this.formStatus = data.form.status;
        changeStaffForm(this.stffForm, data.form);
        this.mainForm.formProperties.forEach(item => (item.model = data.form[item.prop]));
        this.subTableData = data.form.pinvPartList;
      });
    },
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let dynamicUTable = await this.$refs.dynamicUTable.fullValidateMsg();
          this.$refs.dynamicForm.$refs.form.validate((valid, object) => {
            if (valid && !dynamicUTable) {
              this.saveInfo();
            } else {
              let tempStr = '';
              if (dynamicUTable) tempStr += `子表-${dynamicUTable}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(err => console.error(err));
    },
    saveInfo() {
      postNoCatch(pinvAPI.editPinvChange, this.getSaveData()).then(({ data }) => {
        this.$message.success('保存成功!');
        this.initData();
        this.isShow = true;
        this.$emit('isShow', this.isShow);
      });
    },
    getSaveData() {
      let formModel = this.$refs.dynamicForm.getFormModel();
      formModel.pinvchange_id = this.pinvchange_id;
      formModel.pinv_no = this.pinv_no;
      formModel.supp_id = this.supp_id;
      formModel.pinvPartList = this.subTableData;
      return formModel;
    },
    changePinvPartNum(val) {
      this.mainForm.formProperties.find(({ prop }) => prop === 'invoice_num').model = Number(val);
    },
    changePinvPartSubtotal(val) {
      this.mainForm.formProperties.find(({ prop }) => prop === 'invoice_price').model = val;
    },
    changePinvActuNum(val) {
      this.mainForm.formProperties.find(({ prop }) => prop === 'actual_num').model = val;
    },
    changePinvActuSubtotal(val) {
      this.mainForm.formProperties.find(({ prop }) => prop === 'actual_price').model = val;
    },
    changeTaxAccrued(val) {
      this.mainForm.formProperties.find(({ prop }) => prop === 'pinv_price').model = val;
    },
    // 含入附加费
    surcharge() {
      if (this.subSelections.length === 0) return this.$message.warning('请选择一条数据!');
      let podr_nos = getArrayIds(this.subSelections, 'podr_no').toString();
      tranAPI.get_fees_sum({ podr_nos: podr_nos }).then(({ data }) => {
        for (const key in data) {
          let findIndex = this.subTableData.findIndex(({ podr_no }) => podr_no === key);
          this.$set(this.subTableData[findIndex], 'surcharge', data[key]);
        }
        this.$refs.dynamicUTable.clearSelection();
        this.$message.success('已含入');
      });
    },
    // 更换海关编码
    changeCustom(row) {
      this.$set(this.subTableData[this.openIndex], 'prod_hscode', row.param1);
      this.$set(this.subTableData[this.openIndex], 'prod_hsname', row.param2);
      this.$set(this.subTableData[this.openIndex], 'prod_ehsname', row.param3);
      this.$set(this.subTableData[this.openIndex], 'prod_hsinrate', row.param4);
      this.$set(this.subTableData[this.openIndex], 'prod_hsoutrate', row.param5);
      this.dialogTableVisible = false;
    },
    showTranPartDialog(index) {
      this.dialogTableVisible = true;
      this.openIndex = index;
    }
  }
};
</script>

<style scoped lang="scss"></style>
